<template>
  <div class="OtherConfigurations_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item index="1">邮箱配置</el-menu-item>
          <el-menu-item index="2">短信配置</el-menu-item>
          <el-menu-item index="3">短信模板配置</el-menu-item>
          <el-menu-item index="4">公众号模板配置</el-menu-item>
          <el-menu-item index="5">阿里云存储配置</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：包含邮箱、短信、短信模板、公众号模板及阿里云存储配置</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="headerEdit()"><i class="el-icon-plus"></i>编辑</div>
      </div>
      <div v-if="isShow == 1">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>邮件发送方式</div>
          <div>{{mailType[mail_type]}}</div>
        </div>
        <div class="ToConfigure">
          <div>SMTP服务器</div>
          <div>{{maillist.mail_smtp_host}}</div>
        </div>
        <div class="ToConfigure">
          <div>SMTP端口</div>
          <div>{{maillist.mail_smtp_port}}</div>
        </div>
        <div class="ToConfigure">
          <div>SMTP用户名</div>
          <div>{{maillist.mail_smtp_user}}</div>
        </div>
        <div class="ToConfigure">
          <div>SMTP密码</div>
          <div>{{maillist.mail_smtp_pass}}</div>
        </div>
        <div class="ToConfigure">
          <div>SMTP验证方式</div>
          <div>{{mailVerifyType[mail_verify_type]}}</div>
        </div>
        <div class="ToConfigure">
          <div>发件人邮箱</div>
          <div>{{maillist.mail_from}}</div>
        </div>
        <div class="ToConfigure">
          <div>发件人名称</div>
          <div>{{maillist.mail_from_name}}</div>
        </div>
      </div>
      <div v-if="isShow == 2">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>应用AppID</div>
          <div>{{smslist.accessKeyId}}</div>
        </div>
        <div class="ToConfigure">
          <div>应用AppKEY</div>
          <div>{{smslist.accessKeySecret}}</div>
        </div>
        <div class="ToConfigure">
          <div>应用签名</div>
          <div>{{smslist.signName}}</div>
        </div>
      </div>
      <div v-if="isShow == 3">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>用户登录</div>
          <div>{{templatelist.user_login}}</div>
        </div>
        <div class="ToConfigure">
          <div>用户注册</div>
          <div>{{templatelist.user_register}}</div>
        </div>
        <div class="ToConfigure">
          <div>身份确认</div>
          <div>{{templatelist.user_verify}}</div>
        </div>
        <div class="ToConfigure">
          <div>编辑信息</div>
          <div>{{templatelist.user_change}}</div>
        </div>
        <div class="ToConfigure">
          <div>在线报名</div>
          <div>{{templatelist.user_sign}}</div>
        </div>
      </div>
      <div v-if="isShow == 4">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>用户支付</div>
          <div>{{wechatlist.pay}}</div>
        </div>
        <div class="ToConfigure">
          <div>进程更新</div>
          <div>{{wechatlist.flow}}</div>
        </div>
        <div class="ToConfigure">
          <div>申请退款</div>
          <div>{{wechatlist.apply}}</div>
        </div>
        <div class="ToConfigure">
          <div>完成退款</div>
          <div>{{wechatlist.refund}}</div>
        </div>
        <div class="ToConfigure">
          <div>新订单提醒</div>
          <div>{{wechatlist.new_order}}</div>
        </div>
        <div class="ToConfigure">
          <div>开团提醒</div>
          <div>{{wechatlist.tuan_open}}</div>
        </div>
        <div class="ToConfigure">
          <div>参团提醒</div>
          <div>{{wechatlist.tuan_join}}</div>
        </div>
        <div class="ToConfigure">
          <div>拼团成功</div>
          <div>{{wechatlist.tuan_success}}</div>
        </div>
        <div class="ToConfigure">
          <div>拼团失败</div>
          <div>{{wechatlist.tuan_fail}}</div>
        </div>
        <div class="ToConfigure">
          <div>工单审批</div>
          <div>{{wechatlist.shenpi_post}}</div>
        </div>
        <div class="ToConfigure">
          <div>审批结果</div>
          <div>{{wechatlist.shenpi_res}}</div>
        </div>
        <div class="ToConfigure">
          <div>审批消息</div>
          <div>{{wechatlist.shenpi_msg}}</div>
        </div>
      </div>
      <div v-if="isShow == 5">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>应用KEY</div>
          <div>{{aliosslist.accessKeyId}}</div>
        </div>
        <div class="ToConfigure">
          <div>应用SECRET</div>
          <div>{{aliosslist.accessKeySecret}}</div>
        </div>
        <div class="ToConfigure">
          <div>地域节点</div>
          <div>{{aliosslist.endpoint}}</div>
        </div>
        <div class="ToConfigure">
          <div>存储库</div>
          <div>{{aliosslist.bucket}}</div>
        </div>
        <div class="ToConfigure">
          <div>绑定域名</div>
          <div>{{aliosslist.domain}}</div>
        </div>
      </div>
    </div>

    <div class="show" v-if="editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew" style="width:760px">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="editPanel = false"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding:0 26px">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">邮箱配置</el-menu-item>
            <el-menu-item index="2">短信配置</el-menu-item>
            <el-menu-item index="3">短信模板配置</el-menu-item>
            <el-menu-item index="4">公众号模板配置</el-menu-item>
            <el-menu-item index="5">阿里云存储配置</el-menu-item>
          </el-menu>
        </div>
        <el-form :model="Row" :rules="rules" ref="ruleFormRef">
          <div class="EditNewForm" v-if="is_info == 1">
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>邮件发送方式</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="mail.mail_type" style="width:100%">
                  <el-option v-for="(item, key) in mailType" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SMTP服务器</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="mail.mail_smtp_host"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SMTP端口</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="mail.mail_smtp_port"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SMTP用户名</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="mail.mail_smtp_user"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SMTP密码</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="mail.mail_smtp_pass"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SMTP验证方式</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="mail.mail_verify_type" style="width:100%">
                  <el-option v-for="(item, key) in mailVerifyType" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>发件人邮箱</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="mail.mail_from"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>发件人名称</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="mail.mail_from_name"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 2">
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>应用AppID</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="sms.accessKeyId"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>应用AppKEY</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="sms.accessKeySecret"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>应用签名</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="sms.signName"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 3">
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>用户登录</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="smstemplate.user_login"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>用户注册</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="smstemplate.user_register"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>身份确认</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="smstemplate.user_verify"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>编辑信息</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="smstemplate.user_change"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>在线报名</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="smstemplate.user_sign"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 4">
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>用户支付</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.pay"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>进程更新</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.flow"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>申请退款</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.flow"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>完成退款</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.refund"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>新订单提醒</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.new_order"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>催单提醒</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.remind_order"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>开团提醒</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.tuan_open"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>参团提醒</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.tuan_join"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>拼团成功</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.tuan_success"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>拼团失败</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.tuan_fail"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>工单审批</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.shenpi_post"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>审批结果</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.shenpi_res"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>审批消息</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="wechattemplate.shenpi_msg"></el-input>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 5">
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>应用KEY</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="alioss.accessKeyId"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>应用SECRET</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="alioss.accessKeySecret"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>地域节点</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="alioss.endpoint"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>存储库</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="alioss.bucket"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormItem">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>绑定域名</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="alioss.domain"></el-input>
              </div>
            </el-form-item>
          </div>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="editPanel = false">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      mailType:[],
      mailVerifyType:[],
      maillist:{},
      smslist:{},
      templatelist:{},
      wechatlist:{},
      aliosslist:{},
      //邮箱配置
      mail:{
        mail_type:'',
        mail_smtp_host:'',
        mail_smtp_port:'',
        mail_smtp_user:'',
        mail_smtp_pass:'',
        mail_verify_type:'',
        mail_from:'',
        mail_from_name:'',
      },
     //短信配置
      sms:{
        accessKeyId:'',
        accessKeySecret:'',
        signName:'',
      },
      //短信模板配置
      smstemplate:{
        user_login:'',
        user_register:'',
        user_verify:'',
        user_change:'',
        user_sign:'',
      },
      //公众号模板配置
      wechattemplate:{
        pay:'',
        flow:'',
        apply:'',
        refund:'',
        new_order:'',
        remind_order:'',
        tuan_open:'',
        tuan_join:'',
        tuan_success:'',
        tuan_fail:'',
        shenpi_post:'',
        shenpi_res:'',
        shenpi_msg:'',
      },
      //阿里云存储配置
      alioss:{
        accessKeyId:'',
        accessKeySecret:'',
        endpoint:'',
        bucket:'',
        domain:'',
      },
      isShow:1,
      is_info:1,
      activeIndex:'1',
      acndex:1,
      mail_type:'',
      titleMath:'',
      mail_verify_type:'',
      editPanel:false
    }
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    this.loadTable();
    this.Statistics()
  },
  methods: {
    loadTable() {
      var that = this
      axios({
        method: 'get',
        url: '/admin/general/setting/index',
      }).then(function (res) {
        that.maillist =  res.data.data.config.mail
        that.smslist =  res.data.data.config.sms
        that.templatelist =  res.data.data.config.smstemplate
        that.wechatlist =  res.data.data.config.wechattemplate
        that.aliosslist =  res.data.data.config.alioss
        that.mail_type = parseInt(res.data.data.config.mail.mail_type)
        that.mail_verify_type = parseInt(res.data.data.config.mail.mail_verify_type)

      })
    },
    //导航选择
    handleSelect_top(e){
      var that = this
      that.activeIndex = e
      if(e == 1){
        that.isShow = 1
      } else if(e == 2){
        that.isShow = 2
      }else if(e == 3){
        that.isShow = 3
      }else if(e == 4){
        that.isShow = 4
      }else if(e == 5){
        that.isShow = 5
      }
    },
    handleSelect2(e){
      var that = this
      that.acndex = e
      if(e == 1){
        that.is_info = 1
      } else if(e == 2){
        that.is_info = 2
      }else if(e == 3){
        that.is_info = 3
      }else if(e == 4){
        that.is_info = 4
      }else if(e == 5){
        that.is_info = 5
      }
    },
    //统计列表
    Statistics(){
      var that = this  
      axios({
        method: 'get',
        url: '/admin/general/setting/statisticsColumns',
      }).then(function (res) {
        that.mailType = res.data.data.mail_type
        that.mailVerifyType = res.data.data.mail_verify_type
      })
    },
    //详情
    headerEdit(){
      var that = this 
      that.editPanel = true
      that.acndex = 1
      that.is_info = 1
      that.titleMath = '编辑'
      that.Statistics()
      axios({
        method: 'get',
        url: '/admin/general/setting/index',
      }).then(function (res) {
        that.mail =  res.data.data.config.mail
        that.mail.mail_type =  parseInt(res.data.data.config.mail.mail_type)
        that.mail.mail_verify_type =  parseInt(res.data.data.config.mail.mail_verify_type)
        that.sms =  res.data.data.config.sms
        that.smstemplate =  res.data.data.config.smstemplate
        that.wechattemplate =  res.data.data.config.wechattemplate
        that.alioss =  res.data.data.config.alioss
      })
    },
    //编辑
    BtnEdit(){
      var that = this
      let row = {}
      row['mail'] = that.mail
      row['sms'] = that.sms
      row['smstemplate'] = that.smstemplate
      row['wechattemplate'] = that.wechattemplate
      row['alioss'] = that.alioss
      axios({
        method: 'post',
        url: '/admin/general/setting/edit',
        data:{
         row 
        }
      }).then(function(res){
        that.$message.success(res.data.msg);
        that.editPanel = false
        that.isShow = 1
        that.activeIndex = 1
        that.loadTable();
      })
    },
  }
}
</script>